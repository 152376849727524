.grid-full-height {
  flex: 1;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 5vh;
  padding-right: 5vh;
}

.red-background {
  background-color: #ef5350;
  color: black; /* Optional: Ensures text is readable against the red background */
}

.blue-background {
  background-color: #5c6bc0;
  color: black; /* Optional: Ensures text is readable against the red background */
}

.yellow-background {
  background-color: #ffca28;
  color: black; /* Optional: Ensures text is readable against the red background */
}

.green-background {
  background-color: #9ccc65;
  color: black; /* Optional: Ensures text is readable against the red background */
}

.grey-background {
  background-color: #e0e0e0;
  color: black;
}

.grid-item-50-height {
  height: 50%; /* Ensure this item takes up 25% of the height */
}

.space-between-grids {
  height: 1vh;
}